<template>
  <div>
    <div class="row">
      <div class="col-md">
        <b-form
          @submit.stop.prevent="formOnSubmit"
          enctype="multipart/form-data"
        >
          <!-- first card -->
          <Card :title="'Tambah Laporan Lab'">
            <template v-slot:body>
              <div class="row">
                <!-- Input Date -->
                <!-- <div class="col-lg-6 col-12">
                  <b-form-group
                    id="input-group-date"
                    label="Tanggal:"
                    label-for="input-date"
                  >
                    <b-form-datepicker
                      id="input-date"
                      v-model="form.date"
                      locale="id"
                    ></b-form-datepicker>
                    <small class="text-danger">{{ error.date }}</small>
                  </b-form-group>
                </div> -->

                <div class="col-lg-6 col-12">
                  <b-form-group
                    id="input-group-date"
                    label="Tanggal:"
                    label-for="input-date-end"
                  >
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-form-datepicker
                          locale="id"
                          placeholder="Tanggal"
                          v-model="form.date"
                          button-only
                          aria-controls="example-input"
                        ></b-form-datepicker>
                      </b-input-group-prepend>
                      <b-form-input
                        id="input-date-end"
                        v-model="form.date"
                        type="text"
                        placeholder="YYYY-MM-DD"
                        @input="formaterDate"
                      ></b-form-input>
                    </b-input-group>
                    <small class="text-danger">{{ error.date }}</small>
                  </b-form-group>
                </div>

                <!-- Input Modal Trigger -->
                <div class="col-lg-6 col-12">
                  <b-form-group
                    id="input-group-trigger-modal-patient"
                    label="Pasien:"
                    label-for="input-trigger-modal-patient"
                    class="form-group-patient"
                  >
                    <b-form-input
                      id="input-trigger-modal-patient"
                      class="form-patient"
                      v-model="display.patient_name"
                      placeholder="Pilih Pasien"
                      readonly
                      @click="$bvModal.show('modal-patient')"
                    >
                    </b-form-input>
                    <small class="text-danger">{{ error.patient_id }}</small>
                  </b-form-group>
                </div>
              </div>

              <!-- Input Lab Category -->
              <b-form-group
                id="input-group-category"
                label="Kategori:"
                label-for="input-category"
              >
                <treeselect
                  v-model="form.lab_category_id"
                  :multiple="false"
                  :options="categories"
                />
                <small class="text-danger">{{ error.lab_category_id }}</small>
              </b-form-group>

              <!-- Input Doctor -->
              <b-form-group
                v-if="currentUser.user_type_id != 1"
                id="input-group-doctor"
                label="Dokter:"
                label-for="input-doctor"
              >
                <treeselect
                  v-model="form.doctor_id"
                  :multiple="false"
                  :options="doctors"
                />
                <small class="text-danger">{{ error.doctor_id }}</small>
              </b-form-group>

              <b-form-group id="input-group-reference">
                <label for="input-reference"
                  >Dokter Referensi: <em class="text-muted">Opsional</em></label
                >
                <b-form-input
                  id="input-reference"
                  v-model="form.reference"
                  placeholder="Dokter Referensi"
                ></b-form-input>
                <small class="text-danger">{{ error.reference }}</small>
              </b-form-group>

              <b-form-group id="input-group-reference_address">
                <label for="input-reference_address"
                  >Alamat Dokter Referensi:
                  <em class="text-muted">Opsional</em></label
                >
                <b-form-textarea
                  id="input-reference_address"
                  v-model="form.reference_address"
                  placeholder="Alamat Dokter Referensi"
                  rows="4"
                  max-rows="8"
                ></b-form-textarea>
                <small class="text-danger">{{ error.reference_address }}</small>
              </b-form-group>
            </template>
          </Card>

          <!-- attachment card -->
          <Card>
            <template v-slot:body>
              <button
                class="btn btn-info btn-block"
                type="button"
                @click="$refs.inputFile.$el.childNodes[0].click()"
              >
                <i class="fas fa-fw fa-upload"></i> Upload Lampiran
              </button>
              <b-form-file
                ref="inputFile"
                v-model="form.attachment"
                placeholder="Upload Lampiran"
                drop-placeholder="Drop file here..."
                @change="onFileUpload"
                style="display: none"
                multiple
              ></b-form-file>
              <!-- uploaded file list -->
              <div v-if="Array.from(form.display_attachment).length == 0">
                <div class="text-center text-muted mt-5">
                  <i
                    class="fas fa-fw fa-exclamation"
                    style="font-size: 50px"
                  ></i>
                  <div class="h3 mt-2">Belum ada lampiran</div>
                </div>
              </div>
              <div class="row no-gutters mx-n1 mt-2">
                <div
                  class="col-md-4 col-12 p-2"
                  v-for="(data, index) in Array.from(form.display_attachment)"
                  :key="index"
                >
                  <div class="card shadow-sm border-info">
                    <div class="px-3 py-5">
                      <div class="d-flex align-items-center">
                        <div class="h1">
                          <i class="far fa-fw fa-lg fa-file text-info"></i>
                        </div>
                        <div>
                          <div class="d-flex align-items-center mb-2">
                            <div class="mr-1">{{ data.name }}</div>
                            <div
                              class="fas fa-fw fa-times text-danger"
                              @click="btnRemoveFileOnClick(index)"
                            ></div>
                          </div>
                          <div>
                            {{ Math.round((data.size / 100) * 100) / 1000 }} Kb
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Card>

          <!-- report card -->
          <Card>
            <template v-slot:body>
              <!-- Input Template -->
              <button
                class="btn btn-info btn-block"
                type="button"
                @click="
                  isPastientSelected
                    ? $bvModal.show('modal-template')
                    : pushAlert()
                "
              >
                <i class="fas fa-fw fa-upload"></i> Pilih Template
              </button>
              <small class="text-danger">{{ error.lab_template_id }}</small>
              <table class="table">
                <tr>
                  <th>No</th>
                  <th>Jenis Pemeriksaan</th>
                  <th>Hasil</th>
                  <th>Nilai Normal</th>
                  <th>Catatan</th>
                </tr>
                <template v-for="(list, i) in field_collection">
                  <FormResult
                    :key="i"
                    :index="i"
                    :formProps="list"
                    @updateLabReports="updateLabReports"
                    :purpose="purpose"
                    :patient_gender="patient_gender"
                  />
                </template>
              </table>
              <!-- Report input -->
              <!-- <b-form-group
                id="input-group-report"
                label="Laporan:"
                label-for="input-report"
              >
                <div class="document-editor">
                  <ckeditor
                    :editor="editor"
                    @ready="onReady"
                    v-model="form.report"
                  ></ckeditor>
                </div>
                <small class="text-danger">{{ error.report }}</small>
              </b-form-group> -->
            </template>
          </Card>

          <!-- button card -->
          <Card>
            <template v-slot:body>
              <b-button type="submit" variant="primary">Simpan</b-button>
              <b-button
                class="ml-2"
                variant="default"
                @click="$router.push('/lab-report-2/list')"
              >
                Batal
              </b-button>
            </template>
          </Card>
        </b-form>
      </div>
    </div>

    <ModalPatient
      :selectedDoctor="form.doctor_id.toString()"
      @chosenPatient="setPatient"
      @submitedPatient="setPatient"
    />

    <ModalTemplate @chooseTemplate="chooseTemplate" />
  </div>
</template>

<script>
import TreeSelect from "@riophae/vue-treeselect";
import ModalPatient from "@/component/general/ModalPatient.vue";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import ModalTemplate from "@/component/general/ModalTemplate.vue";
import FormResult from "@/component/lab-reports-2/FormResult.vue";
import { getUser } from "@/core/services/jwt.service.js";

export default {
  props: {
    route: String,
    form: Object,
    purpose: {
      type: String,
      default: "add",
    },
  },

  components: {
    ModalPatient,
    Card,
    TreeSelect,
    ModalTemplate,
    FormResult,
  },

  data() {
    return {
      // Display Data
      display: {
        patient_name: "",
      },
      // Select Option
      doctors: [{ label: "Pilih Dokter", id: "", isDisabled: true }],
      categories: [],
      patients: [],
      templates: [],
      // Error
      error: {
        date: "",
        patient_id: "",
        doctor_id: "",
        lab_template_id: "",
        report: "",
        lab_category_id: "",
        attachment: "",
      },
      lab_result_form: {},
      labResultCollection: [],
      formData: new FormData(),
      field_collection: [],
      patient_gender: "",
      formProps: {},
      // other
      doctorDisabled: this.purpose == "edit" ? true : false,
      isPastientSelected: false,
      // current user
      currentUser: getUser(),
    };
  },

  methods: {
    pushAlert() {
      Swal.fire({
        title: "Gagal",
        text: "Harap pilih pasien terlebih dahulu",
        icon: "error",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
      Swal.fire({
        title: "Gagal",
        text: "Harap pilih pasien terlebih dahulu",
        icon: "error",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });

      console.log('test', this.$el.getElementsByClassName("form-group-patient")[0])

      let el = this.$el.getElementsByClassName("form-group-patient")[0];
      el = el ?? this.$el.getElementsByClassName("form-patient")[0];
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
        this.error.patient_id = "Kolom pasien wajib diisi sebelum memilih template"
      }
    },

    formaterDate(val) {
      if (val.length == 4) {
        this.form.date = this.form.date + "-";
      }
      if (val.length == 7) {
        this.form.date = this.form.date + "-";
      }
    },

    onFileUpload(e) {
      Array.from(e.target.files).forEach((value) => {
        this.form.attachment.push(value);
        this.form.display_attachment.push(value);
      });
    },

    btnRemoveFileOnClick(index) {
      this.form.removed_attachment.push(this.form.display_attachment[index]);
      this.form.attachment.splice(index, 1);
      this.form.display_attachment.splice(index, 1);
    },

    chooseTemplate(value) {
      this.$bvModal.hide("modal-template");
      this.formProps = value.field_collection;
      this.field_collection = value.field_collection;
      this.labResultCollection = value.field_collection;
      this.form.lab_template_id = value.id;
    },

    updateLabReports(value, index) {
      console.log("receipt emit updateLabReports", value);
      console.log("receipt emit index", index);
      let labReport = { ...value };
      this.labResultCollection[index] = labReport;
    },

    setPatient(value) {
      this.form.patient_id = value.id;
      this.display.patient_name = value.name;
      this.patient_gender = value.gender;
      this.isPastientSelected = true;
      if (this.currentUser.user_type_id != 1) {
        this.form.doctor_id = "";
        this.getDoctorsOption();
      }
      this.doctorDisabled = false;
      this.$bvModal.hide("modal-patient");
    },

    formaterDate(val) {
      if (val.length == 4) {
        this.form.date = this.form.date + "-";
      }
      if (val.length == 7) {
        this.form.date = this.form.date + "-";
      }
    },

    async formOnSubmit() {
      console.log("lab result", this.labResultCollection);
      this.form.lab_results = JSON.stringify(this.labResultCollection);
      for (const [key, value] of Object.entries(this.form)) {
        if (key == "attachment") {
          value.forEach((element) => {
            this.formData.append("attachment[]", element);
          });
        } else if (key == "removed_attachment") {
          value.forEach((ele) => {
            this.formData.append("removed_attachment[]", JSON.stringify(ele));
          });
        } else if (key == "display_attachment") {
          value.forEach((obj) => {
            this.formData.append("display_attachment[]", JSON.stringify(obj));
          });
        } else {
          this.formData.append(key, value);
        }
      }
      let response = await module.submit(this.formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/lab-report-2/list");
      }
    },

    async getDoctorsOption() {
      let route;
      if (
        typeof this.form.patient_id !== "undefined" &&
        this.form.patient_id != ""
      ) {
        route = `doctors/get-by-patient/${this.form.patient_id}`;
      } else {
        route = "doctors";
      }
      let response = await module.setDoctorTreeSelect(route);
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.doctors = response.data;
        this.doctors.unshift({
          label: "Pilih Dokter",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getCategoriesOption() {
      let response = await module.setTreeSelect("lab-categories");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.categories = response.data;
        this.categories.unshift({
          label: "Pilih Kategori Lab",
          id: "",
          isDisabled: true,
        });
      }
    },

    async templateOnSelect(evt) {
      let selected = await module.get(`lab-templates/${evt.id}`);
      this.form.report = selected.template;
    },

    async getTemplatesOption() {
      let response = await module.setTreeSelect("lab-templates");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.templates = response.data;
        this.templates.unshift({
          label: "Pilih Template",
          id: "",
          isDisabled: true,
        });
      }
    },
  },

  mounted() {
    if (this.currentUser.user_type_id == 1) {
      this.form.doctor_id = this.currentUser.user_id;
    }
    this.getTemplatesOption();
    this.getCategoriesOption();
  },

  watch: {
    "form.patient_id": function (newVal) {
      this.getDoctorsOption();
    },
    "form.patient_name": function (newVal, oldVal) {
      this.display.patient_name = newVal;
    },
    "form.doctor_id": function (newVal) {
      if (typeof newVal === "undefined") this.form.doctor_id = "";
    },
    form: function (newVal, oldVal) {
      if (this.purpose == "edit") {
        console.log("watch edit", this.form);
        this.field_collection = this.form.lab_results;
        this.labResultCollection = this.form.lab_results;
        console.log("field collection edit", this.field_collection);
        console.log("labResultCollection edit", this.labResultCollection);
        this.patient_gender = this.form.patient_gender;
        this.isPastientSelected = true;
      }
    },
  },
};
</script>

