<template>
  <tr>
    <td>
      {{ index + 1 }}

      <span class="d-none">{{patient_gender}}</span>
    </td>
    <td>
      <b-form-group
        id="input-group-field-show-name"
        label=""
        label-for="input-field-show-name"
      >
        <b-form-input
          id="input-field-show-name"
          v-model="form.field_show_name"
          placeholder=""
          readonly
        ></b-form-input>
        <small class="text-danger">{{ error.field_show_name }}</small>
      </b-form-group>
    </td>
    <td>
      <b-form-group
        id="input-group-field-show-name"
        label=""
        label-for="input-field-show-name"
      >
        <b-form-input
          id="input-field-show-name"
          v-model="form.result"
          placeholder=""
          @keyup="rangeCheck"
        ></b-form-input>
        <small class="text-danger">{{ error.result }}</small>
      </b-form-group>
    </td>
    <td>
      <b-input-group>
        <b-form-input
          v-model="form.start_range"
          type="text"
          placeholder="Awal"
          autocomplete="off"
          readonly
        ></b-form-input>
        <b-button squared variant="light">s/d</b-button>
        <b-form-input
          v-model="form.end_range"
          type="text"
          placeholder="Akhir"
          autocomplete="off"
          readonly
        ></b-form-input>
      </b-input-group>
    </td>
    <td>
      <b-form-group id="input-group-notes">
        <b-form-textarea
          id="input-notes"
          v-model="form.notes"
          placeholder="Catatan"
          rows="4"
          max-rows="8"
          @keyup="rangeCheck"
        ></b-form-textarea>
        <small class="text-danger">{{ error.notes }}</small>
      </b-form-group>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    formProps: Object,
    index: Number,
    patient_gender: String,
    purpose: String,
  },
  data() {
    return {
      form: {
        field_show_name: "",
        notes: "",
        result: "",
        field_id:"",
        uom:"",
      },
      error: {
        field_show_name: "",
        notes: "",
        result: "",
      },
    };
  },
  methods:{
    setForm(){
      this.form.field_show_name = this.formProps.field_show_name;
      this.form.field_id = this.formProps.field_id;
      this.form.uom = this.formProps.uom;
      if (this.patient_gender == "male") {
        this.form.start_range = this.formProps.start_range_male;
        this.form.end_range = this.formProps.end_range_male;
      } else {
        this.form.start_range = this.formProps.start_range_female;
        this.form.end_range = this.formProps.end_range_female;
      }

      if(this.purpose == "edit"){
        this.form.result = this.formProps.result
        this.form.notes = this.formProps.notes
      }
        console.log('set form formpros=======================', this.index, this.formProps)
        console.log('set form form===========================',this.index,  this.form)
    },
    rangeCheck(){
      // console.log('form', this.form)
      this.$emit('updateLabReports', this.form, this.index)
    }
  },
  mounted() {
    console.log("formprop mounted", this.formProps);
    console.log("patient_gender mounted", this.patient_gender);
    
    this.setForm()
  },
  watch: {
    formProps: function (newVal, oldVal) {
      console.log("form watch", this.form);
      console.log("formProps watch", this.formProps);
      this.setForm()
    },
    patient_gender: function (newVal, oldVal) {
      this.setForm()
      console.log("patient_gender watch", this.patient_gender);
      if(this.purpose == "edit"){
        this.$emit("updateLabReports", this.form, this.index)
      }
    }
  },
};
</script>

<style>
</style>