<template>
  <div>
    <b-modal
      id="modal-template"
      size="xl"
      title="Pilih Template"
      hide-footer
    >
      <Table purpose="modal-template"  @chooseTemplate="chooseTemplate"/>
    </b-modal>
  </div>
</template>

<script>
import Table from "@/component/masters/lab-templates/Table.vue";

export default {

  components: {
    Table,
  },

  methods: {
    chooseTemplate(value) {
      this.$emit("chooseTemplate", value);
    },
  },
};
</script>

<style>
</style>
