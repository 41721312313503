<template>
  <div>
    <!-- Filter -->
    <div class="row justify-content-end mt-n3">
      <div class="col-md-3">
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            type="text"
            v-model="filter.name"
            placeholder="Cari Nama Template"
            @keyup="filterByName"></b-form-input>
        </b-input-group>
      </div>
    </div>

    <!-- Table -->
    <b-table striped hover class="mt-3" :items="items" :fields="fields">
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'actions' ? '15%' : '' }" />
      </template>
      <template #cell(created_at)="data">
        {{
          new Date(Date.parse(data.item.created_at)).toLocaleString("id-ID", {
            weekday: "long",
            month: "numeric",
            year: "numeric",
            day: "numeric",
          })
        }}
      </template>
      <template #cell(actions)="data">
        <b-button
          size="sm"
          class="mr-1 btn-info"
          v-b-tooltip.hover
          title="Detail"
          @click="chooseTemplate({ id: data.item.id, field_collection: data.item.field_collection })">Pilih</b-button>
      </template>
    </b-table>
    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"></b-pagination>
  </div>
</template>

<script>

import Card from "@/view/content/Card.vue";
import { debouncer } from "@/core/modules/Helper.js"
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "List",

  components: {
    Card,
  },

  data() {
    return {
      // Filter
      filter: {
        name: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Tanggal Dibuat",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      manipulateBtn: false,
      clinic_name:
        window.configuration == null
          ? "Medhop"
          : window.configuration.clinic_name,
    };
  },

  methods: {
    async pagination() {
      let filterParams = `&name=${this.filter.name}`;
      let response = await module.paginate(
        "lab-templates",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.items = response.data;
      this.totalRows = pagination.total;
    },

    pageOnClick(page) {
      this.currentPage = page;
      this.pagination();
    },

    chooseTemplate(value) {
      console.log('click')
      this.$emit("chooseTemplate", value)
    },

    filterByName() {
      debouncer(() => { this.pagination() }, 500)
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("lab-templates/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        ;

        if (access_right[a] == "1105") {
          this.manipulateBtn = true;
        }
      }
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Template Laporan Lab" }]);
    // Get Data
    this.pagination();
    this.setActiveMenu();
  },
};
</script>
